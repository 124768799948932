import { DOMSerializer } from 'prosemirror-model'
import { kebabCase } from 'lodash-es'

const toolbarBtnAttributes = {
  alignLeft: { textAlign: 'left' },
  alignRight: { textAlign: 'right' },
  alignCenter: { textAlign: 'center' },
  alignJustify: { textAlign: 'justify' },
}

export function checkActive(editor) {
  const buttons = document.querySelectorAll('.tiptap-toolbar-btn')
  const activeClass = 'is-active'
  buttons.forEach(function (button) {
    // if (button.disable) {
    //   if (this.editor.can().chain().focus()[button.command]().run())
    //     button.button.removeAttribute('disabled')
    //   else
    //     button.button.setAttribute('disabled', 'true')
    // }
    let name = button.getAttribute('data-tiptap--editor-action-param')
    if (!name) {
      return
    }

    if (toolbarBtnAttributes[name]) {
      name = toolbarBtnAttributes[name]
    }

    if (button.classList.contains(activeClass)) {
      if (!editor.isActive(name)) {
        button.classList.remove(activeClass)
      }
    } else if (editor.isActive(name)) {
      button.classList.add(activeClass)
    }
  })
}

export function fireSetHighlightColorEvent(editorController) {
  let color = editorController.editor.getAttributes('highlight').color
  if (!color) {
    color = fetchDefaultBgColor(editorController)
  }

  if (editorController.hasHighlightColorTarget)
    dispatchSetColorEvent(
      editorController,
      editorController.highlightColorTarget,
      color
    )
}

export function fireSetTextColorEvent(editorController) {
  let color = editorController.editor.getAttributes('textStyle').color
  if (!color) {
    color = fetchDefaultTextColor(editorController)
  }

  if (editorController.hasTextColorTarget)
    dispatchSetColorEvent(
      editorController,
      editorController.textColorTarget,
      color || '#000000'
    )
}

function dispatchSetColorEvent(controller, target, color) {
  controller.dispatch('color-swatch-picker:selectColorOnPalette', {
    target: target,
    prefix: false,
    detail: { color },
  })
}

function fetchDefaultColor(editorController, property) {
  let element = editorController.editor.view.dom.querySelector('.has-focus')
  const textContent = getTextContentBetween(editorController.editor)

  if (element && textContent) {
    element.childNodes.forEach((node) => {
      // The text nodes will not have tagName, for them we need the parent element only
      // The text color is applied inside a span tag so we don't want to match them
      if (
        node.tagName &&
        node.tagName !== 'SPAN' &&
        node.textContent === textContent.trim()
      ) {
        element = node
      }
    })
  }

  if (element) {
    return window.getComputedStyle(element, null).getPropertyValue(property)
  }
}

function fetchDefaultBgColor(editorController) {
  return fetchDefaultColor(editorController, 'background-color')
}

function fetchDefaultTextColor(editorController) {
  return fetchDefaultColor(editorController, 'color')
}

export function getHTMLContentBetween(editor) {
  const { view, state } = editor
  const { from, to } = view.state.selection
  const nodesArray = []

  state.doc.nodesBetween(from, to, (node, pos, parent) => {
    if (parent === state.doc) {
      const serializer = DOMSerializer.fromSchema(editor.schema)
      const dom = serializer.serializeNode(node)
      const tempDiv = document.createElement('div')
      tempDiv.appendChild(dom)
      nodesArray.push(tempDiv.innerHTML)
    }
  })

  return nodesArray.join('')
}

export function getTextContentBetween(editor) {
  const { view, state } = editor
  const { from, to } = view.state.selection
  return state.doc.textBetween(from, to, '')
}

// Create the DOM element because it is not necessary that the selection is always a link - it could be a link inside li
// in that case the selection returns the complete ul element so create an anchor tag element based on the selection
export const createAnchorElementFromSelection = (editor) => {
  const { state } = editor

  const { ranges } = state.selection

  let selectionRange = 0
  const markRanges = []

  ranges.forEach(({ $from, $to }) => {
    const from = $from.pos
    const to = $to.pos

    state.doc.nodesBetween(from, to, (node, pos) => {
      if (!node.isText && !node.marks.length) {
        return
      }

      const relativeFrom = Math.max(from, pos)
      const relativeTo = Math.min(to, pos + node.nodeSize)
      const range = relativeTo - relativeFrom

      selectionRange += range

      markRanges.push(
        ...node.marks.map((mark) => ({
          mark,
          from: relativeFrom,
          to: relativeTo,
        }))
      )
    })
  })

  if (selectionRange === 0) {
    return false
  }

  const matchedRange = markRanges.filter((markRange) => {
    return 'link' === markRange.mark.type.name
  })

  const mark = matchedRange[0]?.mark

  if (mark) {
    const a = document.createElement('a')

    for (const key in mark.attrs) {
      if (mark.attrs[key]) {
        a.setAttribute(kebabCase(key), mark.attrs[key])
      }
    }

    return a
  }
}

export function getExtensionOption(editor, extensionName, optionName) {
  const extension = getExtension(editor, extensionName)

  if (!extension) throw new Error(`Extension ${extensionName} not found`)

  return extension.options[optionName]
}

export function getExtension(editor, extensionName) {
  const extension = editor.extensionManager.extensions.find(
    (extension) => extension.name === extensionName
  )

  return extension
}
